<template>
	<article class="quiz__block true-false">
		<h2 class="quiz__block--title">Question #{{ index }}</h2>
		<p>{{ content }}</p>
		<v-radio-group v-model="answer">
			<v-radio
				v-for="option in responses"
				:key="option.id"
				:id="String(option.id)"
				:value="option.id"
				:label="option.content"
				:name="String(option.id)"
			></v-radio>
		</v-radio-group>
	</article>
</template>

<script>
export default {
	name: "QuizTrueFalse",
	props: {
		content: {
			type: String,
			require: true
		},
		responses: {
			type: Array,
			require: true
		},
		index: {
			type: Number,
			require: true
		},
		selectedAnswers: {
			type: Object,
			require: true
		},
		questionId: {
			type: Number,
			require: true
		}
	},
	computed: {
		answer: {
			get() {
				if (this.selectedAnswers[this.questionId] === undefined) return;
				return this.selectedAnswers[this.questionId];
			},
			set(val) {
				this.$emit("addSelectedAnswer", {
					id: this.questionId,
					value: val
				});
			}
		}
	}
};
</script>
